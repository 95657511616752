<template>
	<div>
		<PageHeader :title="$route.meta.title" />
		<a-card
			:body-style="{ padding: '2px 8px', height: '100%' }"
			class="order-card"
			:tab-list="tabList"
			:active-tab-key="selectedKeys[0]"
			size="small"
			@tabChange="(key) => this.$router.push(key)"
		>
			<div class="orders-info-body">
				<router-view />
			</div>
			<a-tooltip slot="tabBarExtraContent">
				<template slot="title">
					Atualizar
				</template>
				<a-button icon="reload" @click="updateOrders" />
			</a-tooltip>
		</a-card>
	</div>
</template>

<script>
import { PageHeader } from '../../components'
import { mapActions, mapGetters } from 'vuex'
import config from '@/config/defaultSettings'

const TypeOrders = Object.freeze({
	'/orders/pending': 'PENDING',
	'/orders/analyzed': 'ANALYZED',
})

export default {
	name: 'OrdersPage',
	components: {
		PageHeader,
	},
	data() {
		return {
			selectedKeys: [],
			tabList: [
				{
					key: '/orders/pending',
					tab: 'Pendentes',
				},
				{
					key: '/orders/analyzed',
					tab: 'Analisados',
				},
			],
			TypeOrders,
		}
	},
	computed: {
		...mapGetters({
			isMerchantActive: 'merchants/isMerchantActive',
			isAuthorized: 'merchants/isAuthorized',
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
	},
	watch: {
		$route: {
			handler: 'updateMenu',
			immediate: true,
		},
	},
	methods: {
		...mapActions('orders', ['getOrders']),
		updateMenu() {
			const routes = this.$route.matched.concat()
			this.selectedKeys = [routes.pop().path]
		},
		async updateOrders() {
			if (!this.isAuthorized || !this.isMerchantActive) return

			try {
				const orderType = this.TypeOrders[this.selectedKeys[0]]

				await this.getOrders({
					merchantId: this.selectedMerchantId,
					params: {
						order_type: orderType,
						page: 1,
						page_size: config.defaultPageSize,
					},
				})
			} catch (error) {
				console.error(error)
			} finally {
				this.loading = false
			}
		},
	},
}
</script>

<style lang="less" scoped>
.order-card {
	height: 100%;
	border-radius: 5px;

	.orders-info-main {
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 100%;

		.orders-info-header {
			width: 100%;
		}
		.orders-info-body {
			flex: 1 1;
			padding: 0px 5px;
			.orders-info-title {
				color: rgba(0, 0, 0, 0.85);
				font-size: 20px;
				font-weight: 500;
				line-height: 28px;
				margin-bottom: 12px;
			}
			.orders-info-view {
				padding-top: 12px;
			}
		}
	}
}
</style>
